import React, { Component } from 'react'
import classNames from 'classnames'

import { getStatusLabelProps } from '@elo-kit/utils/paymentSetting.utils'
import { isEmpty } from '@elo-kit/utils/validators.utils'

import { convertFromBytes, getExtension } from '@elo-kit/utils/files.utils'
import { IMAGE_EXTENSIONS } from '@elo-kit/constants/files.constants'

import { Ellipsis } from '@elo-kit/components/ellipsis/Ellipsis'
import { FileIcon } from '@elo-kit/components/icons/FileIcon'

import { AnyCallback } from 'types/helpers'
import { patchLink } from 'utils/link.utils'

import './_file-details.scss'

/**
 * FileDetails - show details & remove button for provided file
 */

interface File {
  name?: string
  size?: number
  bytes?: number
  imgUrl?: string
  original?: string
  preview?: string
}
interface Props {
  disabled?: boolean
  border?: boolean
  hideTrashBucket?: boolean
  handleRemove?: AnyCallback
  href?: string
  name?: string
  rightAligned?: boolean
  maxNameLength?: string | number
  progress?: React.ReactNode
  size?: string | number
  state?: string
  uploaded?: boolean
  file?: File
  fileDownloadable?: boolean
  stateMessage?: React.ReactNode
  actionsClassName?: string
  long?: boolean
  originalName?: string
}
interface State {
  icon: React.ReactNode
}

const defaultProps = {
  stateMessage: 'State',
  border: false,
  fileDownloadable: true,
  file: {},
}
export class FileDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      icon: '',
    }
  }

  static defaultProps = defaultProps

  setFileIcon = () => {
    const { file } = this.props
    const preview = new FileReader()

    preview.onloadend = /* istanbul ignore next */ () => {
      this.setState({
        icon: preview.result as string,
      })
    }

    if (!isEmpty(file)) {
      // TODO:
      // @ts-ignore
      preview.readAsDataURL(file)
    }
  }

  render() {
    const {
      border,
      disabled,
      file,
      fileDownloadable,
      handleRemove,
      hideTrashBucket,
      href,
      name,
      maxNameLength,
      progress,
      rightAligned,
      size,
      state,
      uploaded,
      stateMessage,
      actionsClassName,
      long,
      originalName,
    } = this.props

    const { icon } = this.state
    const { bytes, imgUrl, original, preview } = file || {}

    const { statusIconClassName, statusLabelStyle } = getStatusLabelProps(state)
    const filePreview = href || preview || original
    const fileName = file?.name || name || ''
    const extension = getExtension(originalName) || getExtension(file?.name) || getExtension(imgUrl)
    const parsedExtension = (extension ? extension[1] : '') || ''
    const fileExtension = parsedExtension.toLowerCase()
    const isImage = IMAGE_EXTENSIONS.includes(fileExtension)

    const containerClasses = classNames('file-details', {
      'file-details--border': border,
      'file-details--progress': progress,
      'file-details--long': long,
    })

    const actionsClasses = classNames(
      'file-details__actions',
      {
        'file-details__actions--disabled': disabled,
        'file-details__actions--not-deletable': hideTrashBucket,
      },
      actionsClassName
    )

    const fileDetailsSizeClasses = classNames('file-details__size', {
      'file-details__size--right': rightAligned,
    })

    const contentClasses = classNames('file-details__content', {
      'file-details__content--progress': progress,
    })

    const contentContainerClasses = classNames('file-details__content-container', {
      'file-details__content-container--progress': progress,
    })

    const FileLink = ({ children }: any) =>
      filePreview ? (
        // TODO:
        // @ts-ignore
        <a download={!isImage} href={patchLink(fileDownloadable && (isImage ? filePreview : original))} target='_blank'>
          {children}
        </a>
      ) : (
        children
      )

    return (
      <div className={containerClasses}>
        <div className={contentContainerClasses}>
          <FileLink>
            <FileIcon
              extension={fileExtension}
              iconDefault={icon}
              setIcon={this.setFileIcon}
              {...{
                file,
                uploaded,
              }}
            />
          </FileLink>
          <div className={contentClasses}>
            <div className='file-details__text'>
              <div className='file-details__name'>
                <FileLink>
                  <Ellipsis maxLength={Number(maxNameLength)}>{fileName}</Ellipsis>
                </FileLink>
              </div>
              {size && !rightAligned && <div className='file-details__size'>{convertFromBytes(Number(size))}</div>}
            </div>
            {progress && <div className='file-details__progress'>{progress}</div>}
          </div>
        </div>
        {handleRemove && (
          <div className={actionsClasses}>
            {!!state && (
              <div className='file-details__state' style={statusLabelStyle}>
                <i className={statusIconClassName} />
                {stateMessage}
              </div>
            )}
            {rightAligned && <div className={fileDetailsSizeClasses}>{convertFromBytes(file?.size || bytes)}</div>}
            {!hideTrashBucket && (
              <i className='far fa-trash-alt file-details__icon' onClick={!disabled ? handleRemove : () => {}} />
            )}
          </div>
        )}
      </div>
    )
  }
}
